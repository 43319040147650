.App-logo {
  height: 25vmin;
  pointer-events: none;
  // display: flex;
  // align-self: center;
  // justify-content: center;
  // justify-self: center;
}

.app-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-10-padding-10 {
  margin: 10px;
  padding: 10px;
}

.centered {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
  height: 100vh;

  .header {
    height: 20%;
  }

  .body {
    height: 60%;
  }

  .spacer-row {
    height: 10%;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}